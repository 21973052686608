import { format } from 'date-fns';
import { isNil, orderBy, round } from 'lodash';
import {
  BiometricsRawReport,
  BiometricsReport,
  ExternalIdColumn,
  PeriodItem,
} from 'models/reports';

interface CsvHeaderProps {
  externalIdColumns?: ExternalIdColumn[];
  period?: PeriodItem;
}

export const getCsvHeaders = ({ externalIdColumns, period }: CsvHeaderProps) => {
  let startTime;
  if (period?.period === 'weekly') {
    startTime = format(period?.value?.start || 0, 'MM/dd/yyyy');
  } else {
    startTime = format(period?.value?.start || 0, 'MM/yyyy');
  }
  return [
    ...(period?.period === 'monthly'
      ? [{ label: 'Reporting Month', key: 'month' }]
      : [{ label: 'Reporting Week', key: 'week' }]),
    { label: 'Reporting Year', key: 'year' },
    { label: 'First Name', key: 'givenName' },
    { label: 'Last Name', key: 'familyName' },
    { label: 'User ID', key: 'uid' },
    ...(externalIdColumns ?? []),
    { label: 'Account Status', key: 'memberStatus' },
    { label: 'Account Created', key: 'memberCreatedDate' },
    { label: 'Member Since', key: 'memberOnboardedDate' },
    { label: 'Account Age', key: 'memberDaysSinceEnrolled' },
    { label: `Sleep Baseline`, key: 'baselineSleep' },
    { label: `Sleep ${startTime}`, key: 'sleep' },
    { label: `Sleep Improvement`, key: 'improvSleep' },
    { label: `Steps Baseline`, key: 'baselineSteps' },
    { label: `Steps ${startTime}`, key: 'steps' },
    { label: `Steps Improvement`, key: 'improvSteps' },
  ];
};

interface ReportFromBiometricsData {
  rawData: BiometricsRawReport[];
  period: PeriodItem;
}

export const reportFromBiometricsData = ({
  rawData,
  period,
}: ReportFromBiometricsData): BiometricsReport[] => {
  return orderBy(
    rawData.map((item: BiometricsRawReport) => {
      const sleep = item.report_data?.sleep_score ?? null;
      const baselineSleep = item.base_line_data?.sleep_score ?? null;
      const steps = item.report_data?.steps ?? null;
      const baselineSteps = item.base_line_data?.steps ?? null;
      const diffSleep = !isNil(sleep) && !isNil(baselineSleep) ? sleep - baselineSleep : null;
      const diffSteps = !isNil(steps) && !isNil(baselineSteps) ? steps - baselineSteps : null;
      const improvSleep =
        diffSleep && !!baselineSleep ? round((diffSleep / baselineSleep) * 100, 2) : null;
      const improvSteps =
        diffSteps && !!baselineSteps ? round((diffSteps / baselineSteps) * 100, 2) : null;
      return {
        ...(period.period === 'monthly'
          ? { month: period.periodNumber }
          : { week: period.periodNumber }),
        year: period.year,
        givenName: item.first_name ?? '',
        familyName: item.last_name ?? '',
        uid: item.user_id ?? '',
        mrn: item.mrn ?? null,
        externalMrn: item.mrn ?? null,
        externalEmployeeId: item.employee_id || null,
        memberStatus: item.member_status ?? null,
        memberCreatedAt: item.account_created || null,
        memberCreatedDate: item.account_created
          ? format(new Date(item.account_created * 1000 || 0), 'MM/dd/yyyy')
          : null,
        memberOnboardedTimestamp: item.member_since ?? null,
        memberOnboardedDate: item.member_since
          ? format(new Date(item.member_since * 1000 || 0), 'MM/dd/yyyy')
          : null,
        memberDaysSinceEnrolled: item?.member_seconds_enrolled
          ? Math.ceil(item.member_seconds_enrolled / 86400)
          : null,
        sleep: sleep ? round(sleep, 2) : null,
        baselineSleep: baselineSleep ? round(baselineSleep, 2) : null,
        steps: steps ? round(steps, 2) : null,
        baselineSteps: baselineSteps ? round(baselineSteps, 2) : null,
        improvSleep,
        improvSteps,
      };
    }),
    [(report) => report.givenName?.toLowerCase().replace(/[^\w]/gi, '')]
  );
};

export const getDistChartData = (rawData: (number | null)[]) => {
  const result: any = {};
  const patientAmount = rawData.length;
  const filteredData = rawData.filter((i) => !isNil(i)) as number[];
  const minLocalImprov = Math.min(...filteredData);
  const maxLocalImprov = Math.max(...filteredData);
  // Ensure minRange and maxRange are within the bounds of -200 to 200
  let minRange = Math.floor(Math.max(minLocalImprov, -200) / 10) * 10 - 10;
  let maxRange = Math.ceil(Math.min(maxLocalImprov, 200) / 10) * 10;

  // Adjust if minRange exceeds maxRange or falls outside the -200 to 200 range
  minRange = Math.min(minRange, 200);
  maxRange = Math.max(maxRange, -200);
  const step = 10;

  for (let r = minRange; r <= maxRange; r += step) {
    result[r] = 0;
  }

  filteredData.forEach((number) => {
    let range = Math.max(Math.min(Math.floor(number / step) * step, maxRange), minRange);
    result[range] = result[range] + 1;
  });

  const finalResult = Object.keys(result)
    .map((key) => ({
      range: parseInt(key),
      amount: result[key] ? (result[key] / patientAmount) * 100 : 0,
      isMin: key === minRange.toString(),
      isMax: key === maxRange.toString(),
    }))
    .sort((a, b) => a.range - b.range);

  return finalResult;
};
