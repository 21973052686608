import { format } from 'date-fns';
import {
  BillingCareMemberRawData,
  EngagementCareMemberRawData,
  ExternalIdColumn,
  Period,
  PeriodItem,
  TimeTrackingRawReport,
} from 'models/reports';
import { formatSeconds } from './reports';
import { flatMap, keyBy, orderBy, sumBy } from 'lodash';
import { USER_ROLE } from '@constants/team';
import { getUserRole } from './action';

interface ReportFromBillingCareMemberDataReturn {
  billingData: BillingCareMemberRawData[];
  billingByPatient: BillingCareMemberRawData[];
  engagementSummaryData: EngagementCareMemberRawData;
  careTeamSummaryData: Partial<BillingCareMemberRawData>[];
}

interface CsvHeaderProps {
  externalIdColumns?: ExternalIdColumn[];
  period?: Period;
}

export const reportFromBillingCareMemberData = (
  data: TimeTrackingRawReport,
  period: PeriodItem
): ReportFromBillingCareMemberDataReturn => {
  const ctmMap = keyBy(data?.ctms, 'id');
  let totalCareTeamSeconds = 0;

  const billingByPatient = orderBy(
    data?.patients?.map((patient: any) => {
      const ctmUsers = patient?.ctms
        ?.map((ctm: any) => {
          const ctmUser = ctmMap[ctm.id] ?? null;
          if (ctmUser) {
            const careMemberRole = ctmUser.role ? USER_ROLE[getUserRole(ctmUser.role)] : null;
            return {
              careMemberId: ctmUser.id,
              careMemberFamilyName: ctmUser.last_name ?? '',
              careMemberMiddleName: null,
              careMemberGivenName: ctmUser.first_name ?? '',
              careMemberRole: ctmUser.role ?? null,
              careMemberAssignedRole: careMemberRole,
              qhcp: ctmUser.qhcp,
              careTeamTotalSecondsSpent: patient.total_time ?? null,
              careMemberTotalTime: ctm.ctm_time ? formatSeconds(ctm.ctm_time) : null,
            };
          }

          return null;
        })
        .filter((ctm: any) => ctm !== null);
      const careTeamTotalSeconds = patient.total_time ?? null;
      totalCareTeamSeconds = totalCareTeamSeconds + (careTeamTotalSeconds || 0);

      return {
        ...(period.period === 'monthly'
          ? { month: period.periodNumber }
          : { week: period.periodNumber }),
        year: period.year,
        memberGivenName: patient.first_name ?? '',
        memberMiddleName: null,
        memberFamilyName: patient.last_name ?? '',
        uid: patient.id ?? '',
        externalEmployeeId: patient.employee_id || null,
        externalMrn: patient.mrn || null,
        memberStatus: patient.member_status ?? null,
        memberCreatedAt: patient.account_created ?? null,
        memberCreatedDate: patient.account_created
          ? format(new Date(patient.account_created * 1000 || 0), 'MM/dd/yyyy')
          : null,
        memberOnboardedDate: patient.member_since
          ? format(new Date(patient.member_since * 1000 || 0), 'MM/dd/yyyy')
          : null,
        memberOnboardedTimestamp: patient.member_since ?? null,
        memberDaysSinceEnrolled: patient.member_seconds_enrolled
          ? Math.ceil(patient.member_seconds_enrolled / 86400)
          : null,
        totalDaysBandSynced: patient.data_sync ?? null,
        criteriaMet:
          period?.period === 'monthly'
            ? patient.data_sync && patient.data_sync >= 16
              ? 'Yes'
              : 'No'
            : null,
        careTeamTotalSeconds,
        careTeamTotalTime: patient.total_time ? formatSeconds(patient.total_time) : null,
        ctmUsers,
      };
    }),
    [(report) => report.memberGivenName?.toLowerCase().replace(/[^\w]/gi, '')]
  );

  const billingData = orderBy(
    flatMap(
      billingByPatient?.map((patient: any) => {
        return patient?.ctmUsers?.map((ctmUser: any) => {
          return {
            ...patient,
            ...ctmUser,
          };
        });
      })
    ),
    ['uid', 'memberGivenName']
  );

  const engagementSummaryData = {
    usersInThisReport: billingByPatient.length,
    usersOnboarded: sumBy(billingByPatient, (report) => (report.memberOnboardedDate ? 1 : 0)),
    usersActive: sumBy(billingByPatient, (report) => (report.memberStatus === 'ACTIVE' ? 1 : 0)),
    usersDischarged: sumBy(billingByPatient, (report) =>
      report.memberStatus === 'DISCHARGED' ? 1 : 0
    ),
    usersOnHold: sumBy(billingByPatient, (report) => (report.memberStatus === 'HOLDING' ? 1 : 0)),
    usersCriteriaMet: sumBy(billingByPatient, (report) => (report.criteriaMet === 'Yes' ? 1 : 0)),
    totalCareTeamTime: formatSeconds(totalCareTeamSeconds),
  };

  const careTeamSummaryData = orderBy(
    data?.ctms?.map((ctm: any) => ({
      careMemberId: ctm.id,
      careMemberFamilyName: ctm.last_name ?? '',
      careMemberMiddleName: null,
      careMemberGivenName: ctm.first_name ?? '',
      careMemberRole: ctm.role ?? null,
      careMemberAssignedRole: ctm.role ? USER_ROLE[getUserRole(ctm.role)] : null,
      qhcp: ctm.qhcp,
      careMemberTotalTime: ctm.total_time ? formatSeconds(ctm.total_time) : null,
    })) ?? [],
    [(care: any) => care.careMemberGivenName?.toLowerCase().replace(/[^\w]/gi, '')]
  );

  return {
    billingData,
    billingByPatient,
    engagementSummaryData,
    careTeamSummaryData,
  };
};

export const getCsvHeaders = ({ externalIdColumns, period }: CsvHeaderProps) => {
  return [
    ...(period === 'monthly'
      ? [{ label: 'Reporting Month', key: 'month' }]
      : [{ label: 'Reporting Week', key: 'week' }]),
    { label: 'Reporting Year', key: 'year' },
    { label: 'First Name', key: 'memberGivenName' },
    { label: 'Last Name', key: 'memberFamilyName' },
    { label: 'User ID', key: 'uid' },
    ...(externalIdColumns ?? []),
    { label: 'Account Status', key: 'memberStatus' },
    { label: 'Account Created', key: 'memberCreatedDate' },
    { label: 'Member Since', key: 'memberOnboardedDate' },
    { label: 'Account Age', key: 'memberDaysSinceEnrolled' },
    { label: 'Band Compliance', key: 'totalDaysBandSynced' },
    ...(period === 'monthly' ? [{ label: '16d Req', key: 'criteriaMet' }] : []),
    { label: 'CTM First Name', key: 'careMemberGivenName' },
    { label: 'CTM Last Name', key: 'careMemberFamilyName' },
    { label: 'CTM ID', key: 'careMemberId' },
    { label: 'CTM Role', key: 'careMemberAssignedRole' },
    { label: 'QHCP', key: 'qhcp' },
    {
      label: 'CTM Time',
      key: 'careMemberTotalTime',
    },
    {
      label: 'Total Time',
      key: 'careTeamTotalTime',
    },
  ];
};
