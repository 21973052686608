import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import { AxiosRequestConfig } from 'axios';
import { RadiusApiError } from 'services/base';
import { format } from 'date-fns';
import { keyBy } from 'lodash';
import { ActionSpecReport } from 'models/actionSpecReport';
import { PeriodItem } from 'models/reports';
import { User } from 'models/user';

interface RequestParams {
  period: PeriodItem;
  members?: User[];
  isClinician?: boolean;
}

type baseQueryType = (
  arg: AxiosRequestConfig
) => MaybePromise<QueryReturnValue<unknown, RadiusApiError, {}>>;

interface AppComplianceRequestData {
  queryParams: RequestParams;
  baseQuery: baseQueryType;
}

interface ContentInteraction {
  interactions: number;
}

interface ActionInteraction {
  data: ActionSpecReport[];
  count: number;
}

interface FactuaryCounterData {
  tenant_id: string;
  user_id: string;
  first_name: string;
  last_name: string;
  counters: {
    action?: {
      remain_opened?: ActionInteraction;
      expired?: ActionInteraction;
      closed?: ActionInteraction;
      completed?: ActionInteraction;
    };
    insight?: ContentInteraction;
    pledge?: ContentInteraction;
    survey?: ContentInteraction;
  };
}

export interface FactuaryCounter {
  uid: string;
  firstName: string;
  lastName: string;
  insights?: number;
  surveys?: number;
  pledges?: number;
  actionsOpened?: number;
  actionsClosed?: number;
  actionsExpired?: number;
  actionsCompleted?: number;
  actions: ActionSpecReport[];
}

const ACTION_DATE_FORMAT = 'MMM d, yyy hh:mm:ss aa z';

const fetchFactuaryCounters = async ({ queryParams, baseQuery }: AppComplianceRequestData) => {
  const fromDate = format(queryParams.period.value.start, 'yyyyMMdd');
  const toDate = format(queryParams.period.value.end, 'yyyyMMdd');

  const response = await baseQuery({
    url: `/factuary/api/v2/report${
      queryParams.isClinician ? '/by-clinician' : ''
    }?from_date=${fromDate}&to_date=${toDate}`,
    method: 'GET',
  });

  if (response?.data) {
    const countersData = {
      ...(response.data as { data: FactuaryCounterData[] }),
    };
    const countersByUser: Record<string, FactuaryCounter> = keyBy(
      countersData.data.map((counter) => {
        const open: ActionSpecReport[] = counter.counters.action?.remain_opened
          ? counter.counters.action?.remain_opened?.data.map((action) => ({
              ...action,
              status: 'OPEN',
              timeCreated: format(action.time_created * 1000, ACTION_DATE_FORMAT),
              statusAt: action.time_created,
              statusDate: action.time_created
                ? format(action.time_created * 1000, ACTION_DATE_FORMAT)
                : undefined,
              class:
                action.category === 'BIOMETRICS' || action.category === 'HF_BIOMETRICS'
                  ? 'PRIORITY'
                  : 'STANDARD',
            }))
          : [];
        const closed: ActionSpecReport[] = counter.counters.action?.closed
          ? counter.counters.action?.closed?.data.map((action) => ({
              ...action,
              status: 'CLOSED',
              timeCreated: format(action.time_created * 1000, ACTION_DATE_FORMAT),
              statusAt: action.time_closed,
              statusDate: action.time_closed
                ? format(action.time_closed * 1000, ACTION_DATE_FORMAT)
                : undefined,
              class:
                action.category === 'BIOMETRICS' || action.category === 'HF_BIOMETRICS'
                  ? 'PRIORITY'
                  : 'STANDARD',
            }))
          : [];
        const completed: ActionSpecReport[] = counter.counters.action?.completed
          ? counter.counters.action?.completed?.data.map((action) => ({
              ...action,
              status: 'INTERVENED',
              timeCreated: format(action.time_created * 1000, ACTION_DATE_FORMAT),
              statusAt: action.time_completed,
              statusDate: action.time_completed
                ? format(action.time_completed * 1000, ACTION_DATE_FORMAT)
                : undefined,
              class:
                action.category === 'BIOMETRICS' || action.category === 'HF_BIOMETRICS'
                  ? 'PRIORITY'
                  : 'STANDARD',
            }))
          : [];
        const expired: ActionSpecReport[] = counter.counters.action?.expired
          ? counter.counters.action?.expired?.data.map((action) => ({
              ...action,
              status: 'EXPIRED',
              timeCreated: format(action.time_created * 1000, ACTION_DATE_FORMAT),
              statusAt: action.date_expired,
              statusDate: action.date_expired
                ? format(action.date_expired * 1000, ACTION_DATE_FORMAT)
                : undefined,
              class:
                action.category === 'BIOMETRICS' || action.category === 'HF_BIOMETRICS'
                  ? 'PRIORITY'
                  : 'STANDARD',
            }))
          : [];
        return {
          uid: counter.user_id,
          firstName: counter.first_name,
          lastName: counter.last_name,
          insights: counter.counters?.insight?.interactions,
          surveys: counter.counters?.survey?.interactions,
          pledges: counter.counters?.pledge?.interactions,
          actionsOpened: counter.counters.action?.remain_opened?.count,
          actionsClosed: counter.counters.action?.closed?.count,
          actionsExpired: counter.counters.action?.expired?.count,
          actionsCompleted: counter.counters.action?.completed?.count,
          actions: [...open, ...closed, ...completed, ...expired],
        };
      }),
      'uid'
    );

    return countersByUser;
  }

  return {};
};

export default fetchFactuaryCounters;
